import styled from 'styled-components';
import { respondTo } from 'utils/variables';

export const FeatureTitleStyles = styled.div`
  margin: 50px 0 30px;
  position: relative;

  ${respondTo.xs`
    margin-bottom: 6px;
  `}
  .feature-title {
    &__subTitle {
      font-size: 18px;
      line-height: 26px;
      color: #5e6872;
      margin-top: 10px;
    }

    &__title {
      font-weight: bold;
      font-size: ${({ isUppercase }) => (isUppercase ? '20px' : '28px')};
      line-height: ${({ isUppercase }) => (isUppercase ? '30px' : '40px')};
      word-break: break-word;
    }

    &__bottom {
      a {
        white-space: nowrap;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: ${({ isDomain }) =>
          isDomain ? 'var(--color-domain)' : 'var(--primary)'};
      }
    }

    &__bottom {
      a {
        line-height: 30px;
      }
    }

    &__logo-img {
      width: 100px;
      object-fit: contain;
      margin-left: 20px;
      ${respondTo.xs`
        margin-left: 0;
        width: 75px;
      `};
    }
  }

  ${respondTo.xs`
    .feature-title {
      &__subTitle {
        font-size: 13px;
        margin-top: 0px
      }

      &__title {
        font-size: 18px;
        line-height: 20px;
      }

      &__logo-img {
        height: 12px;
      }

      &__bottom {
        a {
          font-size: 14px;
          line-height: 0px;
        }
      }
    }
  `}
`;
