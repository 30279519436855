import { getUserParamsFilterSelector } from '@redux/auth/selectors';
import { useGetAllCentresNearbyQuery } from '@redux/centresNearby';
import { getCurrentGeoLocation } from '@redux/config/selectors';
import { geIsLoadedCarouselByKey } from '@redux/configLocal/selectors';
import { setCarouselHomeLoadedByKey } from '@redux/configLocal/slice';
import { CAROUSEL_HOME_KEYS, CHILDCARE_NEARBY_PATH } from 'configs/constants';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CentreSlide from './CentresSlide';

interface Props {
  isIgnoreTopLoading?: boolean;
  location?: {
    latitude: number;
    longitude: number;
  };
}

const ChildcareNearby = ({ isIgnoreTopLoading, location }: Props) => {
  const currentGeoLocation = useSelector(getCurrentGeoLocation);
  const userParams = useSelector(getUserParamsFilterSelector);
  const dispatch = useDispatch();
  const isLoadedBeforeSlide = useSelector((state) =>
    geIsLoadedCarouselByKey(state, CAROUSEL_HOME_KEYS.brands),
  );

  const isSkipLoading = isIgnoreTopLoading ? false : !isLoadedBeforeSlide;

  const { data, isLoading, isSuccess, isError } = useGetAllCentresNearbyQuery(
    {
      data: {
        limit: 12,
        offset: 0,
        ...(location ?? currentGeoLocation),
        ...userParams,
      },
      options: { isDefaultOrderBy: false },
    },
    {
      skip: isSkipLoading,
    },
  );

  useEffect(() => {
    if (isSuccess || isError) {
      dispatch(
        setCarouselHomeLoadedByKey({
          key: CAROUSEL_HOME_KEYS.childcareNearby,
          value: true,
        }),
      );
    }
  }, [dispatch, isSuccess, isError]);

  return (
    <CentreSlide
      title="home.childcareNearby"
      subTitle="home.childcareNearbyDesc"
      path={CHILDCARE_NEARBY_PATH}
      data={data}
      isLoading={isLoading || isSkipLoading}
    />
  );
};

export default ChildcareNearby;
