import { Skeleton } from 'antd';
import styled from 'styled-components';

const SkeletonCarousel = () => (
  <SkeletonCarouselStyles>
    <Skeleton active paragraph={{ rows: 8 }} />
  </SkeletonCarouselStyles>
);

export default SkeletonCarousel;

const SkeletonCarouselStyles = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;
