import { useGetAllArticlesQuery } from '@redux/articles';
import { getIsLoadedTopCarouselHome } from '@redux/configLocal/selectors';
import FeatureTitle from '@uikit/commons/FeatureTitle';
import ArticleItem from '@uikit/items/ArticleItem';
import Slide from '@uikit/sections/Slide';
import {
  ARTICLE_SLIDE_SETTINGS,
  HINTS_TIPS_STORIES_PATH,
} from 'configs/constants';
import { useSelector } from 'react-redux';
import SkeletonCarousel from './SkeletonCarousel';
import { HomeSectionStyles } from './styles';

interface Props {
  isIgnoreTopLoading?: boolean;
}

const ArticlesStories = ({ isIgnoreTopLoading }: Props) => {
  const isLoadedTopCarouselHome = useSelector(getIsLoadedTopCarouselHome);
  const isSkipLoading = isIgnoreTopLoading ? false : !isLoadedTopCarouselHome;

  const { data, isLoading } = useGetAllArticlesQuery(
    {
      data: {
        offset: 0,
        limit: 12,
      },
    },
    { skip: isSkipLoading },
  );

  if (isLoading || isSkipLoading) return <SkeletonCarousel />;

  return data?.results?.length ? (
    <HomeSectionStyles>
      <FeatureTitle
        title="home.hintsTipsStories"
        subTitle="home.hintsTipsStoriesDesc"
        path={HINTS_TIPS_STORIES_PATH}
      />

      <Slide
        className="slide-h-full"
        autoplay={false}
        isShadowItem
        {...ARTICLE_SLIDE_SETTINGS}
      >
        {data?.results?.map((item) => (
          <ArticleItem item={item} key={item?.id} />
        ))}
      </Slide>
    </HomeSectionStyles>
  ) : null;
};

export default ArticlesStories;
