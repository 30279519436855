import styled from 'styled-components';
import { respondTo } from 'utils/variables';

export const HomeSectionStyles = styled.div`
  margin: 18px 0 70px;

  ${respondTo.xs`
    margin-bottom: 0;
    margin-top: 35px;
  `}
  .brands-slide {
    .slick-slider {
      margin: 0 -8px;
    }
  }

  @media only screen and (max-width: 480px) {
    .brands-slide {
      .slick-slider {
        margin: 0 -6px;
      }
    }
  }
`;

export const ViewAllCardStyles = styled.a`
  cursor: pointer;
  border: 1px solid var(--border-color-light);
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1),
    0px 4px 20px -2px rgba(50, 50, 71, 0.08);
  transition: box-shadow 0.3s, border-color 0.3s;

  &:hover {
    border-color: transparent;
    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),
      0 5px 12px 4px rgb(0 0 0 / 9%);
  }

  .ant-btn-circle {
    border-color: var(--primary);
    background: var(--primary);
    width: 27px;
    min-width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;

    .anticon {
      font-size: 12px;
    }
  }
`;
