import { Card } from 'antd';
import CentreServiceInfo from '@uikit/commons/CentreServiceInfo';
import withTrackingImpression from 'hocs/withTrackingImpression';
import { CentreItem as ICentre } from 'types/centres';
import CentreReviewInfo from '@uikit/commons/CentreReviewInfo';
import CentreAddressDistance from '@uikit/commons/CentreAddressDistance';
import CardItem from '@uikit/items/CardItem';
import AddToCompare from '@uikit/items/CentreItemSecond/AddToCompare';
import BannerCentreItem from './BannerCentreItem';
import CostPerDay from './CostPerDay';
import { CentreItemStyles, CentreName } from './styles';
import ActionWrapperItem from './ActionWrapperItem';

interface Props {
  item?: Partial<ICentre>;
  showLogo?: boolean;
  logoSize?: number;
  fullWidth?: boolean;
  className?: string;
  isExistOnSlide?: boolean;
  hasAddMoreToCompare?: boolean;
  handleClickAddItem?: (item: Partial<ICentre>) => void;
  handleClickRemoveItem?: (item: Partial<ICentre>) => void;
  listCentresAdded: Partial<ICentre>[];
  sizes?: string;
  isFeaturedServicesOfSuburb?: boolean;
}

const CentreItem = ({
  item = {},
  showLogo,
  logoSize,
  fullWidth = true,
  isExistOnSlide = true,
  className,
  hasAddMoreToCompare,
  handleClickAddItem,
  handleClickRemoveItem,
  listCentresAdded,
  sizes,
  isFeaturedServicesOfSuburb = false,
}: Props) => {
  const isSelected = listCentresAdded?.find(
    (centre) => centre?.id === item?.id,
  );

  const toggleCompare = () => {
    isSelected ? handleClickRemoveItem(item) : handleClickAddItem(item);
  };

  return (
    <CardItem
      fullWidth={fullWidth}
      isExistOnSlide={isExistOnSlide}
      className="h-full"
    >
      <ActionWrapperItem
        isLink={!hasAddMoreToCompare}
        item={item}
        onClick={toggleCompare}
      >
        <CentreItemStyles showLogo={showLogo} className={className}>
          <Card
            hoverable
            bordered={false}
            className={`shadow ${isSelected && 'selected-card-item'}`}
            cover={
              <BannerCentreItem
                fullWidth={fullWidth}
                item={item}
                showLogo={showLogo}
                logoSize={logoSize}
                sizes={sizes}
              />
            }
          >
            <div className="flex-center-between items-start">
              <CentreName
                isFeaturedServicesOfSuburb={isFeaturedServicesOfSuburb}
                className={
                  isFeaturedServicesOfSuburb ? 'ellipsis-1-t' : 'ellipsis-2-t'
                }
              >
                {item?.name}
              </CentreName>
              <CostPerDay item={item} />
            </div>

            {isFeaturedServicesOfSuburb ? null : (
              <div className="centre-item__content">
                <div className="centre-item__info-item">
                  <CentreAddressDistance item={item} isEllipsis />
                </div>
                <div className="flex-center-between flex-wrap gap-8">
                  <CentreServiceInfo
                    centreValue={item?.bestService?.centreValueForMoney}
                    className="centre-item__info-item"
                    valueClassName="centre-item__info-item-value"
                  />
                  <CentreReviewInfo
                    reviewStarAverage={item?.reviewStarAverage}
                    reviewCount={item?.reviewCount}
                  />
                </div>
                {hasAddMoreToCompare && (
                  <AddToCompare
                    handleClickRemoveItem={handleClickRemoveItem}
                    handleClickAddItem={handleClickAddItem}
                    hasAddMoreToCompare={hasAddMoreToCompare}
                    listCentresAdded={listCentresAdded}
                    item={item}
                  />
                )}
              </div>
            )}
          </Card>
        </CentreItemStyles>
      </ActionWrapperItem>
    </CardItem>
  );
};

export default withTrackingImpression(CentreItem as any);
