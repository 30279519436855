import PropTypes from 'prop-types';
import Link from '@uikit/commons/Link';
import { getLinkCentreDetail } from 'utils/tools';

const ActionWrapperItem = ({ isLink, item, children, ...props }) =>
  isLink ? (
    <Link href={getLinkCentreDetail(item)}>{children}</Link>
  ) : (
    <div {...props}>{children}</div>
  );

ActionWrapperItem.propTypes = {
  isLink: PropTypes.bool,
  item: PropTypes.object,
};

export default ActionWrapperItem;
