import PropTypes from 'prop-types';
import FeatureTitle from '@uikit/commons/FeatureTitle';
import CentreItem from '@uikit/items/CentreItem';
import Slide from '@uikit/sections/Slide';
import {
  CARD_SLIDE_SETTINGS,
  CENTRE_DETAIL_PATH,
  PARENT_HOME_PAGE_PATH,
} from 'configs/constants';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCurrentUserSelector } from '@redux/auth/selectors';
import { useRouter } from 'next/router';
import { EVENT_TRACKING } from 'configs/eventTracking';
import { HomeSectionStyles } from './styles';
import SkeletonCarousel from './SkeletonCarousel';

const CentreSlide = ({ data, title, subTitle, path, isLoading }) => {
  const { t } = useTranslation();
  const currentUser = useSelector(getCurrentUserSelector);
  const { pathname, asPath } = useRouter();
  const NEARBY_CENTRES = 'Nearby Centres';
  const POPULAR_NEARBY = 'Popular Nearby';
  const generateImpressionPayload = (item, index) => {
    if (pathname === PARENT_HOME_PAGE_PATH) {
      return {
        loggingData: {
          eventName:
            EVENT_TRACKING.CENTRES.IMPRESSION
              .centreImpressionLandingPageCarousel,
          eventData: {
            userId: currentUser?.id || null,
            centreId: item?.id,
            appearingIndex: index,
            carouselName: t(title),
          },
        },
      };
    }

    if (
      !!(pathname === CENTRE_DETAIL_PATH && t(title) === NEARBY_CENTRES) ||
      !!(pathname === CENTRE_DETAIL_PATH && t(title) === POPULAR_NEARBY)
    ) {
      return {
        loggingData: {
          eventName:
            t(title) === NEARBY_CENTRES
              ? EVENT_TRACKING.CENTRES.IMPRESSION
                  .centreImpressionCentreDetailsNearbyCentreCarousel
              : EVENT_TRACKING.CENTRES.IMPRESSION
                  .centreImpressionCentreDetailsPopularNearbyCarousel,
          eventData: {
            userId: currentUser?.id || null,
            centreId: item?.id,
            appearingIndex: index,
            url: asPath,
          },
        },
      };
    }
    return null;
  };

  if (isLoading) return <SkeletonCarousel />;
  return data?.results?.length ? (
    <HomeSectionStyles>
      <FeatureTitle title={title} subTitle={subTitle} path={path} />

      <Slide
        autoplay={false}
        isShadowItem
        className="slide-h-full"
        {...CARD_SLIDE_SETTINGS}
      >
        {data?.results?.map((item, index) => (
          <CentreItem
            item={item}
            key={item?.id}
            impression={generateImpressionPayload(item, index)}
            rootMargin="20px 12px"
            showLogo
            sizes="(max-width: 480px) 80vw, (max-width: 768px) 50vw, 30vw"
          />
        ))}
      </Slide>
    </HomeSectionStyles>
  ) : null;
};

CentreSlide.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isLoading: PropTypes.bool,
};

export default CentreSlide;
