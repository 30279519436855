import { useTranslation } from 'react-i18next';
import { Button, Card } from 'antd';
import { HINTS_TIPS_STORIES_PATH } from 'configs/constants';
import Link from '@uikit/commons/Link';
import { useDispatch } from 'react-redux';
import { trackingEvent } from '@redux/eventTracking/actions';
import { EVENT_TRACKING } from 'configs/eventTracking';
import { ArticleItemStyles } from '@uikit/items/ArticleItem/styles';
import { Article } from 'types/article';
import CoverImageRatio from '../CoverImageRatio';
import CardItem from '../CardItem';

const { Meta } = Card;

interface Props {
  item?: Article;
  fullWidth?: boolean;
  isExistOnSlide?: boolean;
}

const ArticleItem = ({
  item = {},
  fullWidth = true,
  isExistOnSlide = true,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClickItem = () => {
    if (item?.externalUrl) {
      dispatch(
        trackingEvent({
          eventName: EVENT_TRACKING.externalArticleClicked,
          eventData: { articleId: item.id, externalUrl: item.externalUrl },
        }),
      );
    }
  };

  return (
    <CardItem
      fullWidth={fullWidth}
      isExistOnSlide={isExistOnSlide}
      className="h-full"
    >
      <Link
        href={
          item?.externalUrl ||
          `${HINTS_TIPS_STORIES_PATH}/${item?.slug || item?.id}`
        }
        externalUrl={item?.externalUrl}
        target="_blank"
        className="h-full"
      >
        <ArticleItemStyles onClick={handleClickItem}>
          <Card
            hoverable
            bordered
            cover={
              <div className="article-item__cover-wrapper">
                <CoverImageRatio
                  fullWidth={fullWidth}
                  src={item?.thumbnail}
                  height={307}
                />
                {item.isSponsored && (
                  <Button className="article-item__sponsored">
                    {t('button.sponsored')}
                  </Button>
                )}
              </div>
            }
          >
            <div className="text-highlight text-uppercase fw-bold size-12 lh-18">
              {item?.categories?.[0]?.parentCategory?.name ||
                item?.categories?.[0]?.name}
            </div>
            <Meta className="lh-24" title={item?.heading} />
            <div className="article-item__desc ellipsis-t text-secondary">
              {item?.subheading}
            </div>
          </Card>
        </ArticleItemStyles>
      </Link>
    </CardItem>
  );
};

export default ArticleItem;
