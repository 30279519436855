import PropTypes from 'prop-types';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { isFunction } from 'lodash-es';
import { UrlObject } from 'url';
import { FeatureTitleStyles } from './styles';

interface Props {
  subTitle?: string;
  title?: string;
  btnText?: string;
  path?: string | UrlObject;
  isDomain?: boolean;
  rawTitle?: string;
  isUppercase?: boolean;
  triggerTrackingEvent?: () => void;
}

const FeatureTitle = ({
  subTitle,
  rawTitle,
  title,
  btnText = 'button.viewAll',
  path,
  isDomain,
  isUppercase,
  triggerTrackingEvent,
}: Props) => {
  const { t } = useTranslation();
  const handleTrackingEvent = () => {
    if (isFunction(triggerTrackingEvent)) {
      triggerTrackingEvent();
    }
  };

  return (
    <FeatureTitleStyles
      isUppercase={isUppercase}
      isDomain={isDomain}
      className="feature-title-wrapper"
    >
      <div className="feature-title__bottom d-flex justify-content-between items-center">
        <div className="feature-title__title-wrapper">
          <div className="d-flex items-center">
            <div
              className={`feature-title__title ${
                isUppercase ? 'text-uppercase' : ''
              }`}
            >
              {rawTitle || t(title)}
            </div>
            {isDomain && (
              <img
                alt=""
                className="feature-title__logo-img"
                src="/svg/logo-domain.svg"
              />
            )}
          </div>
        </div>

        {!!path && (
          <div className="feature-title__link-wrapper">
            <Link href={path} passHref>
              <a href="#" onClick={handleTrackingEvent}>
                {t(btnText)}
              </a>
            </Link>
          </div>
        )}
      </div>
      {subTitle && <div className="feature-title__subTitle">{t(subTitle)}</div>}
    </FeatureTitleStyles>
  );
};

FeatureTitle.propTypes = {
  subTitle: PropTypes.string,
  title: PropTypes.string,
  btnText: PropTypes.string,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isDomain: PropTypes.bool,
  rawTitle: PropTypes.string,
  isUppercase: PropTypes.bool,
  triggerTrackingEvent: PropTypes.func,
};

export default FeatureTitle;
