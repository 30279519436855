import styled from 'styled-components';
import { respondTo } from 'utils/variables';

export const ArticleItemStyles = styled.div`
  height: 100%;
  .ant-card {
    height: 100%;
  }
  .ant-card-body {
    min-height: unset;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    ${respondTo.sm`
      padding: 12px;
      gap: 4px;
    `}
    &::before, &::after {
      content: none;
    }
  }

  .ant-card-bordered {
    overflow: hidden;
  }
  .article-item {
    &__cover-wrapper {
      position: relative;
    }
    &__cover {
      height: 307px;
      object-fit: cover;
      width: 100%;
    }
    &__sponsored {
      position: absolute;
      top: 10px;
      left: 10px;
      border-radius: 8px;
      background: #ebecf070;
      border: none;
      color: var(--text-secondary);
      font-weight: 700;
    }
  }
  .ant-card-meta-title {
    font-weight: bold;
    font-size: 16px;
  }
`;
